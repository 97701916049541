<template>
	<div class="user-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
		<b-modal v-model="modal" centered hide-header-close
		         ok-variant="danger"
		         cancel-variant="custom-light"
		         v-bind:ok-disabled="!password || !newPw || !confirmPw"
		         v-on:ok="changePw"
		         v-on:hide="resetModal">
			<h3 slot="modal-title">{{modalTitle}}</h3>
			<h5 slot="modal-ok" class="m-0">Confirm</h5>
			<h5 slot="modal-cancel" class="m-0">Cancel</h5>
			<form action="" class="container-modal-form position-relative w-100 h-100 p-3">
				<div class="form-group position-relative">
					<label for="password" class="font-weight-bold">Password</label>
					<input type="password" id="password" class="form-control position-relative w-100"
					       v-bind:disabled="modalTitle === 'Password Changed !'"
					       v-bind:class="{'invalid': pwInvalid}"
					       v-model="password"
					       v-on:input="pwInvalid = false">
				</div>
				<div class="form-group position-relative">
					<label for="newPw" class="font-weight-bold">New Password</label>
					<input type="password" id="newPw" class="form-control position-relative w-100"
					       v-bind:disabled="modalTitle === 'Password Changed !'"
					       v-bind:class="{'invalid': newPwsInvalid}"
					       v-model="newPw"
					       v-on:input="newPwsInvalid = false">
					<h6 class="position-relative mt-1">- Minimum 10 Characters Long</h6>
				</div>
				<div class="form-group position-relative">
					<label for="confirmPw" class="font-weight-bold">Confirm Password</label>
					<input type="password" id="confirmPw" class="form-control position-relative w-100"
					       v-bind:disabled="modalTitle === 'Password Changed !'"
					       v-bind:class="{'invalid': newPwsInvalid}"
					       v-model="confirmPw"
					       v-on:input="newPwsInvalid = false">
				</div>
			</form>
		</b-modal>
		<div class="details position-relative w-75 h-100 rounded bg-white d-flex flex-column align-items-center overflow-hidden">
			<form-controls v-bind:msg="msg"
			               v-bind:msg-invalid="msgInvalid">
				<control-button slot="left"
				                v-bind:disabled="false"
				                v-bind:btn-title="backBtnTitle"
				                v-bind:btn-icon="['fas', 'arrow-left']"
				                v-bind:btn-class="backBtnClass"
				                v-on:btn-pressed="goBack"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!savable"
				                btn-title="SAVE"
				                v-bind:btn-icon="['fas', 'hdd']"
				                btn-class="btn-custom-light ml-2"
				                v-on:btn-pressed="saveUser"></control-button>
				<control-button slot="right"
				                v-bind:disabled="false"
				                btn-title="Change Password"
				                v-bind:btn-icon="['fas', 'key']"
				                btn-class="btn-danger ml-2"
				                v-on:btn-pressed="modal = true"></control-button>
			</form-controls>
			<form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
				<div class="container-avatar position-relative w-100 mb-3 text-center">
					<img v-bind:src="localUser.avatar" alt="Avatar" class="avatar position-relative rounded-circle"
					     v-on:error="imageError">
				</div>
				<div class="form-group position-relative w-100">
					<label for="avatar" class="font-weight-bold">Profile Picture</label>
					<input type="text" id="avatar" placeholder="Profile Picture" class="form-control position-relative"
					       v-bind:class="invalidInputs.avatar"
					       v-bind:value="localUser.avatar"
					       v-on:input="updateUser('avatar', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="name" class="font-weight-bold">Name</label>
					<input type="text" id="name" placeholder="Last Name" class="form-control position-relative"
					       v-bind:class="invalidInputs.name"
					       v-bind:value="localUser.name"
					       v-on:input="updateUser('name', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="phone" class="font-weight-bold">Phone Number</label>
					<input type="text" id="phone" placeholder="Phone Number" class="form-control position-relative"
					       v-bind:class="invalidInputs.phone"
					       v-bind:value="localUser.phone"
					       v-on:input="updateUser('phone', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="email" class="font-weight-bold">Email</label>
					<input type="text" id="email" placeholder="Email" class="form-control position-relative"
					       v-bind:value="localUser.email" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="role" class="font-weight-bold">Access Right</label>
					<input type="text" id="role" placeholder="Email" class="form-control position-relative"
					       v-bind:value="localUser.role" readonly>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import ControlButton from "@/components/functional/form/ControlButton";
import isMobilePhone from "validator/lib/isMobilePhone";
import isURL from "validator/lib/isURL";
import isLength from "validator/lib/isLength";
import {BModal} from "bootstrap-vue";

export default {
	name: "UserDetails",
	
	components: {
		FormControls,
		ControlButton,
		BModal
	},
	
	beforeRouteLeave(to, from, next) {
		this.modal = false;
		if (this.savable) {
			if (this.msg === "Leave Without Saving?") {
				// user has already been notified
				next();
			} else {
				this.toRoute = to.fullPath;
				this.msg = "Leave Without Saving?";
				this.msgInvalid = true;
				this.backBtnTitle = "YES!";
				this.backBtnClass = "btn-danger mr-2";
			}
		} else {
			next();
		}
	},
	
	created() {
		this.initUser();
		window.addEventListener("beforeunload", this.unloadHandler);
	},
	
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.unloadHandler);
	},
	
	data() {
		return {
			localUser: {},
			invalidInputs: {
				name: "",
				phone: "",
				// can be invalid but not required
				avatar: "",
			},
			msg: "",
			msgInvalid: false,
			modal: false,
			toRoute: "",
			backBtnTitle: "BACK",
			backBtnClass: "btn-custom-light mr-2",
			modalTitle: "Change Password",
			password: "",
			pwInvalid: false,
			newPw: "",
			confirmPw: "",
			newPwsInvalid: false,
		}
	},
	
	computed: {
		user() {
			return this.$store.state.user.user;
		},
		
		savable() {
			// fall through pattern
			const keys = ["name", "phone", "avatar"];
			return keys.some(key => this.localUser[key] !== this.user[key]);
		},
	},
	
	methods: {
		unloadHandler(event) {
			if (this.savable) {
				event.preventDefault();
				event.returnValue = '';
			}
		},
		
		resetModal(bvModalEvent) {
			if (bvModalEvent.trigger !== "ok") {
				this.modalTitle = "Change Password";
				this.password = "";
				this.newPw = "";
				this.confirmPw = "";
				this.pwInvalid = false;
				this.newPwsInvalid = false;
			}
		},
		
		imageError(e) {
			e.target.src = require("../../assets/media/user_backup.png");
		},
		
		initUser() {
			this.localUser = {...this.user};
		},
		
		updateUser(key, value) {
			this.localUser[key] = value;
			switch (key) {
				// avatar is not a required field
				case "avatar":
					if (value.trim().length > 0) {
						this.invalidInputs[key] = isURL(value) ? "" : "invalid";
					} else {
						this.invalidInputs[key] = "";
					}
					break;
				case "phone":
					this.invalidInputs[key] = isMobilePhone(value) ? "" : "invalid";
					break;
				default:
					// check if value is just white spaces
					this.invalidInputs[key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
		},
		
		// only set invalid, shouldn't set it back to empty string
		// nullish check only, no other validations since they are being taken care of in input event handlers
		dataInvalid() {
			const keys = Object.keys(this.invalidInputs);
			keys.forEach(key => {
				// avatar can be null or empty or white space, it's not required
				if (key !== "avatar" && !this.localUser[key] ||
					this.localUser[key].trim().length === 0) this.invalidInputs[key] = "invalid";
			});
			return keys.some(key => this.invalidInputs[key] === "invalid");
		},
		
		async saveUser() {
			this.backBtnTitle = "BACK";
			this.backBtnClass = "btn-custom-light mr-2";
			if (this.dataInvalid()) {
				this.msg = "Please Fix Invalid Fields!";
				this.msgInvalid = true;
			} else {
				// need not do deep copy for contacts since initUser will be run upon successful saving
				const user = {...this.localUser};
				await this.$store.dispatch("user/updateUser", user);
				this.initUser();
				this.msg = "Previous Changes Saved!";
				this.msgInvalid = false;
			}
		},
		
		newPwIsInvalid() {
			// fall through pattern
			if (!this.newPw || !this.confirmPw ||
				this.newPw.trim().length === 0 || this.confirmPw.trim().length === 0) return true;
			if (!isLength(this.newPw, {min: 10, max: 64})) return true;
			return this.newPw !== this.confirmPw;
		},
		
		async changePw(bvModalEvent) {
			if (this.modalTitle === "Change Password") {
				bvModalEvent.preventDefault();
				if (this.password !== this.$store.state.user.user.password) {
					this.pwInvalid = true;
					if (this.newPwIsInvalid()) this.newPwsInvalid = true;
				} else {
					this.pwInvalid = false;
					if (this.newPwIsInvalid()) {
						this.newPwsInvalid = true;
					} else {
						this.newPwsInvalid = false;
						const user = {...this.user};
						user.password = this.newPw;
						await this.$store.dispatch("user/updateUser", user);
						this.modalTitle = "Password Changed !";
					}
				}
			} else {
				this.modalTitle = "Change Password";
				this.password = "";
				this.newPw = "";
				this.confirmPw = "";
				this.pwInvalid = false;
				this.newPwsInvalid = false;
			}
		},
		
		async goBack() {
			if (this.msg === "Leave Without Saving?") {
				await this.$router.push(this.toRoute);
			} else {
				await this.$router.push("/client/analytics");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/form";
.avatar {
	width: 120px;
	height: 120px;
	min-width: 120px;
	min-height: 120px;
	object-fit: cover;
	border: 5px solid rgba(0,0,0,.3);
	transition: all .2s ease;
}
</style>